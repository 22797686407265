<template>
	<div id="adoptOrderDetail">
		<page-order-detail :is-shop="0"></page-order-detail>
	</div>
</template>

<script>
import pageOrderDetail from '@/components/layout/adopt/page-order-detail.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'
export default{
	components:{
		pageOrderDetail
	},
	setup(){
		onBeforeRouteLeave((to,from,next)=>{
			if( to.path == '/adopt/orderList'){
				store.dispatch("setKeepPage",{name:to.name,type:1})
			}else{
				store.dispatch("setKeepPage",{name:to.name,type:2})
			}
			next()
		})
	}
}
</script>

<style lang="scss">

</style>
